import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import { Link } from 'react-router-dom'
import FixedPriceBlock from './FixedPriceBlock'

const FixedPriceFirst = () => {

  const fixedListInd = [
    {   title : 'Для індивідуальних та колективних побутових споживачів, а також споживачів (цілі споживання), які визначені в абзацах одинадцятому — чотирнадцятому пункту 13 розділу XVII <a href="https://zakon.rada.gov.ua/laws/show/2019-19#n1589"> “Прикінцеві та перехідні положення” Закону України “Про ринок електричної енергії”</a>',
        volume: {text : 'Місячний обсяг споживання', kv: '≤ 250'},
        count : {all: '4,32', pdvOn: '0,72', pdvOff: '3,6'},
    },
    // {   volume: {text : 'Місячний обсяг споживання', kv: '> 250'},
    //     count : {all: '1,68', pdvOn: '0,28', pdvOff: '1,40'},   
    // },
  ]

  const fixedListCollect = [
    {   title : 'Для індивідуальних та колективних побутових споживачів, які проживають в житлових будинках (у тому числі в житлових будинках готельного типу, квартирах), обладнаних в установленому порядку електроопалювальними установками: <span> у період з 1 червня по 30 вересня (включно); </span>',
        volume: {text : 'За весь обсяг споживання'},
        count : {all: '4,32', pdvOn: '0,72', pdvOff: '3,6'}, 
        sm: true, 
    }
  ]

  const fixedListCollectBefore = [
    {   title : '<span>у період з 1 жовтня по 30 квітня (включно):</span> <p>- до 2000 кВт∙год спожитої електричної енергії на місяць (включно);</p>',
        volume: {text : 'За весь обсяг споживання'},
        count : {all: '2,64', pdvOn: '0,44', pdvOff: '2,2'},
        sm: true, 
    }
  ]

  const fixedListCollectAfter = [
    {   title : '- понад 2000 кВт∙год спожитої електричної енергії на місяць;',
        volume: {text : 'За весь обсяг споживання'},
        count : {all: '4,32', pdvOn: '0,72', pdvOff: '3,6'}, 
        sm: true, 
    }
  ]

  const fixedListNoGazBefore = [
    {   title : 'Для побутових споживачів, які проживають у багатоквартирних будинках, що не газифіковані і в яких відсутні або не функціонують системи централізованого теплопостачання або системи автономного теплопостачання, які використовують будь-які види енергоносіїв, крім електричної енергії, <span> у період з 1 січня по 30 квітня (включно): </span> <p>- до 2000 кВт∙год спожитої електричної енергії на місяць (включно);</p>',
        volume: {text : 'За весь обсяг споживання'},
        count : {all: '2,64', pdvOn: '0,44', pdvOff: '2,2'}, 
        sm: true, 
    }
  ]

  const fixedListNoGazAfter = [
    {   title : '- понад 2000 кВт∙год спожитої електричної енергії на місяць;',
        volume: {text : 'За весь обсяг споживання'},
        count : {all: '4,32', pdvOn: '0,72', pdvOff: '3,6'}, 
        sm: true, 
    }
  ]
    
  return (
    <Container>
        <Row>
            <Col md={12}>
            <div className='fixed-title__inner'>
              <div className="fixed-title__number">01</div>
              <p className="fixed-title__descr">Фіксовані ціни на електричну енергію для побутових споживачів <br /> 
              становлять з 
              <span> 1 червня 2024 р.</span> по 
              <span> 30 квітня 2025 р. включно:</span></p>
            </div>
            </Col>
        </Row>
        <div>
          <FixedPriceBlock fixedList={fixedListInd}/>
          
          <FixedPriceBlock fixedList={fixedListCollect}/>
          <FixedPriceBlock fixedList={fixedListCollectBefore}/>
          <FixedPriceBlock fixedList={fixedListCollectAfter}/>

          <FixedPriceBlock fixedList={fixedListNoGazBefore}/>
          <FixedPriceBlock fixedList={fixedListNoGazAfter}/>

        </div>
        <Row className='fixed-price-polozhen'>
          <Col md={12} className='fixed-price-rules'>
            <Link to = 'https://zakon.rada.gov.ua/laws/show/2019-19#Text:~:text=%D0%A0%D0%BE%D0%B7%D0%B4%D1%96%D0%BB%20XVII-,%D0%9F%D0%A0%D0%98%D0%9A%D0%86%D0%9D%D0%A6%D0%95%D0%92%D0%86%20%D0%A2%D0%90%20%D0%9F%D0%95%D0%A0%D0%95%D0%A5%D0%86%D0%94%D0%9D%D0%86%20%D0%9F%D0%9E%D0%9B%D0%9E%D0%96%D0%95%D0%9D%D0%9D%D0%AF,-1.%20%D0%A6%D0%B5%D0%B9%20%D0%97%D0%B0%D0%BA%D0%BE%D0%BD' target='_blank'>
                «Прикінцеві та перехідні положення» <br /> Закону України «Про ринок електричної енергії»</Link>
                <img src="/img/fixed-price-download.svg" alt="download" />
          </Col>
        </Row>
    </Container>
  )
}

export default FixedPriceFirst