import './styles/global.scss';
import './styles/style.scss';
import './styles/header.scss';
import './styles/home-slider.scss';
import './styles/news-slider.scss';
import './styles/footer.scss';

import React from 'react';
import { RouterProvider, createBrowserRouter} from "react-router-dom";
import Home from "./pages/Home";
import CenterObslug from "./pages/Collabor/CenterObslug";
import SendPokazCli from "./pages/Clients/SendPokazCli";
import NoPage from "./pages/NoPage";
import Tarifs from './pages/Company/Tarifs';
import News from './pages/News';
import NewsPage from './pages/NewsPage';
import Vacancies from './pages/Clients/Vacancies';
import Layout from './pages/Layout';
import PaymentCli from './pages/Clients/PaymentCli';
import CommercialOffers from './pages/Company/CommercialOffers';
import CalculatorCli from './pages/Clients/CalculatorCli';
import TarifCli from './pages/Clients/TarifCli';
import ApiContentPage from './pages/ApiContentPage';
// import ServiceOnlineCli from './pages/ServiceOnline/ServiceOnlineCli';
// import ServiceOnlineCompany from './pages/ServiceOnline/ServiceOnlineCompany';
// import ServiceOnlineCliService from './pages/ServiceOnline/Clients/ServiceOnlineCliService';
// import ServiceOnlineCompanyService from './pages/ServiceOnline/Company/ServiceOnlineCompanyService';
import Pay from './pages/Pay';
import RatingCons from './pages/RatingCons';
import Assignment from './pages/Collabor/Assignment';
import Application from './pages/Collabor/Application';
// import InfoClient from './pages/Clients/InfoClient'; // static info
// import InfoCompany from './pages/Company/InfoCompany'; // static info
import CreateContract from './pages/Collabor/CreateContract';
import SearchPage from './pages/SearchPage';
import PaymentCom from './pages/Company/PaymentCom';
import ServicesCli from './pages/Clients/ServicesCli';
import ServicesCom from './pages/Company/ServicesCom';
import AccountPage from './pages/AccountPage';
import InfoPage from './pages/Collabor/InfoPage';

const errorMsg = '404';


const router = createBrowserRouter([
  {
      path: "/",
      element: <Layout />,
      errorElement: <NoPage error={errorMsg}/>,
      children: [
          { index: true, element: <Home /> },
          {
            path: "/news",
            element: <News />,
            errorElement: <NoPage/>,
          },
          {
            path: "/news/:id",
            element: <NewsPage />,
            errorElement: <NoPage/>,
          },
          {
            path: "/company/center-obslug",
            element: <CenterObslug type = {'Для компаній'} />,
            errorElement: <NoPage/>,
          },
          {
            path: "/company/tarif",
            element: <Tarifs />,
            errorElement: <NoPage/>,
          },
          {
            path: "/company/contract",
            element: <CreateContract type= {'Для компаній'} consumer={2}/>,
            errorElement: <NoPage/>,
          },
          {
            path: "/company/commercial",
            element: <CommercialOffers />,
            errorElement: <NoPage/>,
          },
          {
            path: "/company/assignment",
            element: <Assignment type = {'Для компаній'} />,
            errorElement: <NoPage/>,
          },
          {
            path: "/company/application",
            element: <Application type = {'Для компаній'} />,
            errorElement: <NoPage/>,
          },
          {
            path: "/company/info",
            element: <InfoPage type = {'Для компаній'} />,
            errorElement: <NoPage/>,
          },
          {
            path: "/company/payment",
            element: <PaymentCom/>,
            errorElement: <NoPage/>,
          },
          {
            path: "/company/services",
            element: <ServicesCom/>,
            errorElement: <NoPage/>,
          },
          {
            path: "/clients/center-obslug",
            element: <CenterObslug type = {'Для населення'} />,
            errorElement: <NoPage/>,
          },
          {
            path: "/clients/services",
            element: <ServicesCli/>,
            errorElement: <NoPage/>,
          },
          {
            path: "/clients/send-pokaz",
            element: <SendPokazCli />,
            errorElement: <NoPage/>,
          },
          {
            path: "/clients/info",
            element: <InfoPage type = {'Для населення'} />,
            errorElement: <NoPage/>,
          },
          {
            path: "/clients/vacancies",
            element: <Vacancies/>,
            errorElement: <NoPage/>,
          },
          {
            path: "/clients/assignment",
            element: <Assignment type = {'Для населення'} />,
            errorElement: <NoPage/>,
          },
          {
            path: "/clients/application",
            element: <Application type = {'Для населення'} />,
            errorElement: <NoPage/>,
          },
          {
            path: "/clients/payment",
            element: <PaymentCli/>,
            errorElement: <NoPage/>,
          },
          {
            path: "/clients/contract",
            element: <CreateContract type = {'Для населення'} consumer={1}/>,
            errorElement: <NoPage/>,
          },
          {
            path: "/clients/calculator_pobut",
            element: <CalculatorCli/>,
            errorElement: <NoPage/>,
          },
          {
            path: "/clients/tarif",
            element: <TarifCli/>,
            errorElement: <NoPage/>,
          },
          {
            path: "/page/:id",
            element: <ApiContentPage />,
            errorElement: <NoPage/>,
          },
          {
            path: "/pay",
            element: <Pay/>,
            errorElement: <NoPage/>,
          },
          // {
          //   path: "/clients/service_online",
          //   element: <ServiceOnlineCli/>,
          //   errorElement: <NoPage/>,
          // },
          // {
          //   path: "/company/service_online",
          //   element: <ServiceOnlineCompany/>,
          //   errorElement: <NoPage/>,
          // },
          // {
          //   path: "/clients/service_online/:service_name",
          //   element: <ServiceOnlineCliService/>,
          //   errorElement: <NoPage/>,
          // },
          // {
          //   path: "/company/service_online/:service_name",
          //   element: <ServiceOnlineCompanyService/>,
          //   errorElement: <NoPage/>,
          // },
          {  path: "/search-page",
            element: <SearchPage/>,
            errorElement: <NoPage/>,
          },
          {  path: "/rating_cons/:kons_id",
            element: <RatingCons/>,
            errorElement: <NoPage/>,
          },
          {  path: "/e_account",
             element: <AccountPage/>,
             errorElement: <NoPage/>,
        },
      ],
  },
]);

function App() {

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
