
// import GreenForm from "../../components/GreenForm";
import TitleBlock from "../../components/TitleBlock";
import CalculatorPrice from "../../components/TarifCompany/CalculatorPrice"
import SliderNews from "../../components/News/SliderNews";
import { useEffect, useState } from "react";
import OperPeriod from "../../components/TarifCompany/OperPeriod";
import { getOperPrice, getOperRozp } from "../../Axios/calculationsYr";
import { useOutletContext } from "react-router-dom";
import ApiForm from "../../components/ApiForm";

const Tarifs = () => {

  const [rozpodilList, SetRozpodilList] = useState([]);
  const [lastmonth, SetLastMonth] = useState('');
  const [,,theme] = useOutletContext();
  const [yearList, setYearList] = useState([]);

  const monthList = [
    {id:'12', name:'Грудень'},
    {id:'11', name:'Листопад'},
    {id:'10', name:'Жовтень'},
    {id:'09', name:'Вересень'},
    {id:'08', name:'Серпень'},
    {id:'07', name:'Липень'},
    {id:'06', name:'Червень'},
    {id:'05', name:'Травень'},
    {id:'04', name:'Квітень'},
    {id:'03', name:'Березень'},
    {id:'02', name:'Лютий'},
    {id:'01', name:'Січень'},
  ];

    useEffect(() => {
        window.scrollTo(0,0);
        getOperRozp().then((response)=>{  
            SetRozpodilList(response["hydra:member"])
        })
        // визначенння періодів - з
        getOperPrice({"pagination": true, "order[period]" : "asc" }).then((response)=>{
          const [data] = response["hydra:member"];
            const from = parseInt(data.period.split('_')[0]);
            // визначенння періодів - по
            getOperPrice({"pagination": true, "order[period]" : "desc" }).then((response)=>{
              const [data] = response["hydra:member"];
                SetLastMonth(data.period);
                const to = parseInt(data.period.split('_')[0]);
                const years = Array.from({ length: to - from + 1 }, (_, index) => to - index);
                setYearList(years);
            })
        }).catch(err => {
          console.log(err);
        })
      }, [])

      const type1 = `ТОВ «Львівенергозбут» інформує про ціни на універсальні послуги з постачання електричної енергії для непобутових клієнтів.`;
      const subt = `Ціна універсальної послуги`;
      const type2 = `Будь ласка, спочатку оберіть оператора системи розподілу, період тарифного плану та клас напруги`;

      // const optionsActivity = ['ТОВ','ПАТ','ПрАТ', 'ГО', 'ФОП', 'ПП','ТОВ','ТзОВ','ФО', 'Освіта','Наука', 'Медицина','ОСББ', 'Дачні', 'КП','Кооперативи','Гаражні','Інше'];

        return (
           <>
           {theme === 'light' && <div className="mont-block"></div>}
             <TitleBlock title={'Тарифи'} type={type1}/>
             <TitleBlock subtitle={subt} type={type2}/>
             <OperPeriod rozpodilList = {rozpodilList} monthList = {monthList} yearList = {yearList} lastmonth = {lastmonth}/>
             <CalculatorPrice rozpodilList = {rozpodilList} monthList = {monthList} yearList = {yearList} lastmonth = {lastmonth}/>
             <TitleBlock subtitle = {'Цікавить ціна електроенергії для клієнтів із дозволеною потужністю понад 50 кВт?'} addClass = {"price-title"}/>
             <ApiForm id={20}/>
             {/* <GreenForm optionsActivity={optionsActivity}/> */}
             <SliderNews/>
          </>
     );
}

export default Tarifs