import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const InfoPage = ({type}) => {
  const navigate = useNavigate();
  
  useEffect(() => {
        window.scrollTo(0,0);
        // сторінки перенесені в адмін панель
        switch(type){
            case 'Для населення': navigate('/page/info_clients');
            break;
            case 'Для компаній': navigate('/page/info_company');
            break;
            default: navigate('/');
        }
  }, [navigate, type])

  return (<></>)
}

export default InfoPage