// import GreenForm from "../../components/GreenForm";
import TitleBlock from "../../components/TitleBlock";
// import YellowContact from "../../components/YellowContact";
import SliderNews from "../../components/News/SliderNews";
import { useEffect } from "react";
import PickTsok from "../../components/PickTsok";
import { useOutletContext } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";

const CenterObslug = ({type}) => {

  // const optionsActivity = ['ТОВ','ПАТ','ПрАТ', 'ГО', 'ФОП', 'ПП','ТОВ','ТзОВ','ФО', 'Освіта','Наука', 'Медицина','ОСББ', 'Дачні', 'КП','Кооперативи','Гаражні','Інше'];
  // const optionsСommerce = [
  //   {id: 31, type: 'Комерційна пропозіцїя №5/2 Тарифний план «Базовий з тарифом ОСР» Оплата плановими платежами'},
  //   {id: 9, type: 'Комерційна пропозіцїя №4/1 Тарифний план «Базовий з тарифом ОСР» Оплата не плановими платежами'},
  //   {id: 11, type: 'Комерційна пропозіцїя №3/5 Тарифний план «Базовий з тарифом ОСР» Оплата плановими платежами'},
  //   {id: 7, type: 'Комерційна пропозіцїя №7/1 Тарифний план «Базовий з тарифом ОСР» Оплата не плановими платежами'},
  // ];

  const [,,theme] = useOutletContext();
  
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

    return (
       <>
         {theme === 'light' && <div className="mont-block"></div> }
         <TitleBlock title={'Центри обслуговування клієнтів'} type={type}/>
         {/* <YellowContact/> */}
         <Container>
          <div className="tsok-content item-news">
              <h3>Послуги Центру:</h3>
              <ol>
                <li>Укладання договорів про постачання електричної енергії та консультації щодо умов договорів та комерційних пропозицій.</li>
                <li>Консультації щодо укладання договорів про постачання природного газу.</li>
                <li>Консультації з питань розрахунків за спожиту електричну енергію та природний газ, коригування рахунків, надання роз’яснень про нарахування. </li>
                <li>Укладання договорів про реструктуризацію заборгованості.</li>
                <li>Надання роз’яснень з питань реєстрації та роботи онлайн-сервісу «Персональний кабінет».</li>
              </ol>
          </div>
         </Container>
         <PickTsok type={type}/>
         {/* <GreenForm optionsActivity={optionsActivity} optionsСommerce={optionsСommerce}/> */}
         <SliderNews/>
      </>
    );
};


export default CenterObslug;